import React from "react";

import GradientAnimation from "../GradientAnimation";
import TopNav from "../TopNav";

import CardScrollerAI from "./CardScrollerAI";
import CardVideo from "./CardVideo";

import CardIntegration from "./CardIntegration";

import CardScrollerFeature from "./CardScrollerFeature";
import Plan from "../Pricing/Plan";

import CampaignBanner from "../CampaignBanner";

import TryFreeTrial from "../TryFreeTrial";

import Footer from "../Footer";
import FeatureList from "./FeatureList";
import OurClient from "./OurClient";
import Testimonials from "./Testimonials";
import GridContainer from "../GridContainer";
import LogisticsProvider from "../../../static/lottie/05-2-Logistics-Provider.json";
import AdminChatbot from "../../../static/lottie/02_AdminMode.json";
import AllChat from "../../../static/lottie/02_AllChat.json";
import Paper from "../../../static/lottie/02_Paper.json";
import CreditCardPaymentImage from "../../../static/image/campaign/credit-card-payment.png";
import ZortImage from "../../../static/image/campaign/zort.png";
import openAPIImage from "../../../static/image/campaign/open-api.png";
import Goods1Image from "../../../static/image/campaign/goods-1.png";
import Goods2Image from "../../../static/image/campaign/goods-2.png";
import Goods3Image from "../../../static/image/campaign/goods-3.png";
import Goods4Image from "../../../static/image/campaign/goods-4.png";
import Goods5Image from "../../../static/image/campaign/goods-5.png";
import Store from "../../../static/image/campaign/store.png";

const CampaignChatbot: React.FC = () => (
  <>
    <GradientAnimation />

    <div className="center-container">
      <TopNav isSignInShown={false} />
    </div>

    <GridContainer>
      <CampaignBanner />
    </GridContainer>

    <GridContainer>
      <CardVideo />
    </GridContainer>

    <GridContainer headerClassName="relative">
      <CardScrollerFeature />
    </GridContainer>

    <GridContainer>
      <CardScrollerAI />
    </GridContainer>

    <GridContainer>
      <CardIntegration
        card={{
          headerText1: "deeple Platform Integrations",
          headerText2: "เชื่อมต่อแพลตฟอร์มขายออนไลน์ครบวงจร",
          headerText3: "ให้ทุกการซื้อ-ขายไม่มีสะดุด",
          cardDetails: [
            {
              text1: "Logistics Provider & COD",
              text2: "ขนส่งชั้นนำและเก็บเงินปลายทาง",
              videoLink: LogisticsProvider,
              background: "",
              textColor: "",
            },
            {
              text1: "Payment Gateway",
              text2: "ชำระเงินผ่านหลายช่องทาง",
              videoLink: null,
              image: CreditCardPaymentImage,
              background: "bg-black",
              textColor: "text-white",
              marginBottom: "mb-5",
            },
            {
              text1: "ZORT",
              text2: "ระบบจัดการออเดอร์และสต็อก",
              videoLink: null,
              image: ZortImage,
              background: "bg-custom-dark-blue",
              textColor: "text-white",
              marginBottom: "mb-0",
            },
            {
              text1: "Open API",
              text2: "API สำหรับจัดการร้านค้าที่คุณต้องการ",
              videoLink: null,
              image: openAPIImage,
              background: "bg-black",
              textColor: "text-white",
              marginBottom: "mb-13",
            },
          ],
        }}
      />
    </GridContainer>
    <GridContainer>
      <CardIntegration
        card={{
          headerText1: "deeple Store Management Features",
          headerText2: "ฟีเจอร์ระบบจัดการร้านค้า",
          headerText3: "ช่วยงานขายได้ตั้งแต่ต้นจนจบ",
          cardDetails: [
            {
              text2: "สลับการทำงานแอดมินกับแชทบอทตามต้องการ",
              videoLink: AdminChatbot,
              background: "",
              textColor: "",
              lottieClassName: "pb-0",
            },
            {
              text2: "รวมแชทลูกค้าจากทุกแพลตฟอร์ม",
              videoLink: AllChat,
              isDarkBackgroundVideoIcon: true,
              background: "bg-black",
              textColor: "text-white",
              marginBottom: "mb-5",
              lottieClassName: "pb-0",
            },
            {
              text2: "จัดการออเดอร์ <br>พิมพ์ใบเสร็จและใบปะหน้าพัสดุ",
              videoLink: Paper,
              background: "bg-white",
              textColor: "",
              marginBottom: "mb-0",
              lottieClassName: "pb-0",
            },
            {
              text2: "รายงานการขายและข้อมูลลูกค้า",
              videoLink: null,
              image: Store,
              background: "bg-black",
              textColor: "text-white",
            },
          ],
        }}
      />
    </GridContainer>
    <GridContainer>
      <CardIntegration
        card={{
          headerText1: "deeple, Solution for All Business Types",
          headerText2: "เพิ่มทางเลือกการขายออนไลน์",
          headerText3: "เพื่อหลากหลายประเภทธุรกิจ",
          cardDetails: [
            {
              text2: "สินค้าทั่วไป สินค้าสำเร็จรูป",
              image: Goods1Image,
              background: "bg-white",
              textColor: "",
              marginBottom: "mb-7",
            },
            {
              text2: "สินค้าที่ต้องปรึกษาเชิงลึก",
              videoLink: null,
              image: Goods2Image,
              background: "bg-white",
              textColor: "",
              marginBottom: "mb-0",
            },
            {
              text2: "สินค้าพรีออเดอร์ ขายส่ง",
              videoLink: null,
              image: Goods3Image,
              background: "bg-white",
              textColor: "",
              marginBottom: "mb-0",
            },
            {
              text2: "สินค้าที่มีการออกแบบเฉพาะ <br>(Custom Products)",
              videoLink: null,
              image: Goods4Image,
              background: "bg-white",
              textColor: "",
              marginBottom: "mb-0",
            },
            {
              text2: "คูปอง บัตรเติมเงิน บัตรของขวัญ",
              videoLink: null,
              image: Goods5Image,
              background: "bg-white",
              textColor: "",
              marginBottom: "mb-5",
            },
          ],
        }}
      />
    </GridContainer>

    <GridContainer>
      <FeatureList />
    </GridContainer>

    <GridContainer>
      <OurClient />
    </GridContainer>

    <GridContainer headerClassName="pt-10">
      <Testimonials />
    </GridContainer>

    <GridContainer>
      <div className="relative pb-10">
        <div className="relative">
          <div className="card-scroller-container p-6">
            <div className="text-center">
              <h3 className="text-white font-weight-600 gradient-text dashboard-heading-2-gradient pb-2 m-auto w-fit-content">
                Choose Your deeple Plan
              </h3>
              <h2 className="cards-shelf-mainheader dashboard-heading-2">
                เริ่มต้นใช้งาน deeple ฟรี&nbsp;
              </h2>
              <span className="cards-shelf-secondaryheader dashboard-heading-2">
                หรือเลือก Plan ที่เหมาะกับธุรกิจของคุณ
              </span>
            </div>
          </div>
        </div>
        <Plan isShowHeader={false} />
      </div>
    </GridContainer>

    <GridContainer>
      <TryFreeTrial />
    </GridContainer>

    <div>
      <Footer />
    </div>
  </>
);

export default CampaignChatbot;
