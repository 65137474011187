import React from "react";
import "../CardScroller/index.css";

import LottiePlayer from "../LottiePlayer";

import AiVisualProductSearch from "../../../static/lottie/03-1-Ai-Visual-Product-Search.json";
import AiAddressRecognition from "../../../static/lottie/03-3-Ai-Address-Recognition.json";
import AiUpSellIntelligent from "../../../static/lottie/03-2-Ai-UpSell-Intelligent.json";
import AiiLiveToChat from "../../../static/lottie/03-5-Ai-Live-to-Chat.json";
import AiIgToChat from "../../../static/lottie/03-Ig-Comment-to-chat.json";

type CardPropsType = {
  children?: React.ReactNode;
};

const CardScrollerAI: React.FC<CardPropsType> = () => {
  const cardDetails = [
    {
      text1: "AI Visual Product Search",
      text2: "ค้นหาสินค้าด้วยรูปภาพ",
      videoLink: AiVisualProductSearch,
    },
    {
      text1: "AI Upsell Intelligence",
      text2: "เสนอโปรโมชันอัตโนมัติ",
      videoLink: AiUpSellIntelligent,
    },
    {
      text1: "AI Address Recognition",
      text2: "อ่านและจดจำที่อยู่อัตโนมัติ",
      videoLink: AiAddressRecognition,
    },
    {
      text1: "FB Live and Comment to Chat",
      text2: "ดูดคอมเมนต์ จาก Facebook",
      videoLink: AiiLiveToChat,
    },
    {
      text1: "IG Comment and Story to Chat",
      text2: "ดูดคอมเมนต์ IG Post และตอบกลับ IG Story",
      videoLink: AiIgToChat,
      isLessPadding: true,
    },
  ];
  return (
    <div className="relative">
      <div className="card-scroller-container p-6">
        <div>
          <h3 className="text-white font-weight-600 gradient-text dashboard-heading-2-gradient pb-2 w-fit-content">
            deeple Advanced Features
          </h3>
          <h2 className="cards-shelf-main-header dashboard-heading-2">
            เทคโนโลยี AI และฟีเจอร์
          </h2>
          <span className="cards-shelf-secondary-header dashboard-heading-2">
            ที่ยกระดับประสบการณ์ซื้อ-ขายอย่างแตกต่าง
          </span>
        </div>
      </div>
      <div className="dp-cards-scroller">
        <div className="dp-cards-scroller-crop dp-card-height-830">
          <div className="dp-cards-scroller-content dp-card-height-830">
            <div className="dp-cards-scroller-platter dp-card-height-830">
              {cardDetails.map((card) => {
                return (
                  <div
                    className="dp-cards-scroller-item-view-ai"
                    key={card.text1}
                  >
                    <div className="dp-card-width-360">
                      <div className="dp-card-content dp-card-width-360 dp-card-height-700">
                        <div className="dp-card-img-full-wrapper mt-16">
                          <div className="mt-8">
                            <LottiePlayer
                              key={card.text1}
                              file={card.videoLink}
                              height={430}
                              width={320}
                              lottieClassName="pb-10"
                            />
                          </div>
                        </div>
                        <div
                          className={`dp-card-content-info ${
                            card.isLessPadding && "px-2"
                          }`}
                        >
                          <h3 className="dp-card-content-eyebrow">
                            {card.text1}
                          </h3>
                          <div className="dp-card-content-header">
                            <div className="dp-card-content-header-link">
                              {card.text2}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardScrollerAI;
